<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS INCIDENCIAS DE SOFTWARE</span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col md="3" sm="6" class="my-2 icon-custom vr">
                            <i style="inline-size: auto" class="ico fas fa-laptop fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaIncidenciaFinalizadas.length}}</span>
                            <br>
                            <span class="text-muted">Total registradas</span>
                        </b-col>
                        <b-col md="3" class="my-2 icon-custom vr">
                            <i style="inline-size: auto" class="ico far fa-flag fa-3x"></i> <br>
                            <span class="lg-numero">{{listaIncidenciaFinalizadas.filter(a=>a.estado==1).length}}</span>
                            <br>
                            <span class="text-muted">Pendientes</span>
                        </b-col>
                        <b-col md="3" class="my-2 icon-custom vr">
                            <i style="inline-size: auto" class="ico far fa-clock fa-3x"></i> <br>
                            <span class="lg-numero">{{listaIncidenciaFinalizadas.filter(a=>a.estado==2).length}}</span>
                            <br>
                            <span class="text-muted">En desarrollo</span>
                        </b-col>
                        <b-col md="3" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico far fa-check-square fa-3x"></i> <br>
                            <span class="lg-numero">{{listaIncidenciaFinalizadas.filter(a=>a.estado==3).length}}</span>
                            <br>
                            <span class="text-muted">Finalizadas</span>
                        </b-col>
                    </b-row>

                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Incidencias de software</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="4" class="my-2">
                            <b-button block :to="{name: 'Incidencias de software'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Incidencias de software finalizadas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaIncidenciaFinalizadas.filter(a=>a.estado==3)" :fields="campoIncidenciasFinalizadas" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" responsive fixed hover>
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(acciones)="param">
                                    <b-button v-if="checkPermissions('011-INC-INS','u')==1" @click="abrirModalReabrirIncidencia(param)" class="mr-1 mb-1" size="sm" variant="warning" v-c-tooltip="'Reabrir'">
                                        <i class="fas fa-folder-open mx-0 my-0 text-white"></i> </b-button>
                                </template>
                                <template #cell(estado)="data">
                                    <h5 v-if="data.value=='3'">
                                        <b-badge variant="success">
                                            <i class="fas fa-check-square fa-md"></i> Finalizada
                                        </b-badge>
                                    </h5>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalReabrirIncidencia">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-folder-open fa-md"></i>
                <span class="h5"> Reabrir incidencia </span>
            </h6>
            <CButtonClose @click="modalReabrirIncidencia = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(reabrirIncidencia)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="tipo de tarea" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo de tarea:" class="mb-2">
                                <b-form-select v-model="datosIncidencia.tipoTarea" :options="comboTipoTarea">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="indique su incidencia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Indique su incidencia:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" v-model="datosIncidencia.incidencia" :state="getValidationState(validationContext)" placeholder="Ingrese la incidencia" size="md"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12" class="my-2">
                        <span>Cargar documentos:</span>
                        <CButton class="float-right mt-2" @click="agregarFilaDocumentos()" size="sm" color="dark">
                            <i class="fas fa-plus fa-sm"></i> Agregar documento
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosIncidencia.listaDocumentos" :fields="campoDocumentos" class="table-custom" mediun responsive outlined fixed hover>
                            <template v-slot:cell(documento)="row">
                                <b-form-file v-model="row.item.documento" :placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="row.item.nombreDocumento ? row.item.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                            </template>
                            <template #cell(acciones)="param">
                                <b-button v-if="param.item.idIncidenciaSoftware!=''" @click="eliminarDocumento(param)" v-show="datosIncidencia.listaDocumentos.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                   <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                                <b-button v-if="param.item.idIncidenciaSoftware==''" @click="quitarFilaDocumento(param)" v-show="datosIncidencia.listaDocumentos.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                   <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalReabrirIncidencia = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalReabrirIncidencia: false,
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            comboTipoTarea: [{
                    value: '1',
                    text: 'Modificación/Mejora'
                },
                {
                    value: '2',
                    text: 'Error de funcionamiento'
                }
            ],
            campoIncidenciasFinalizadas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "fechaNotificacion",
                    label: "Fecha de notificaicón",
                    class: "text-center",
                }, {
                    key: "fechaPrevistaResolucion",
                    label: "Fecha prevista de resolución",
                    class: "text-center",
                }, {
                    key: "incidencia",
                    label: "Descripción",
                    class: "text-center",
                }, {
                    key: "fechaResolucion",
                    label: "Fecha de resolución",
                    class: "text-center",
                }, {
                    key: "comentarioProgramador",
                    label: "Comentarios programador",
                    class: "text-center",
                }, {
                    key: "estado",
                    label: "Estado",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            campoDocumentos: [{
                    key: "documento",
                    label: "Documento",
                },
                {
                    key: "acciones",
                    class: "text-center"
                }
            ],
            listaIncidenciaFinalizadas: [],
            datosIncidencia: {
                idIncidenciaSoftware: '',
                tipoTarea: null,
                incidencia: '',
                fechaNotificacion: '',
                fechaPrevistaResolucion: '',
                fechaResolucion: '',
                comentarioProgramador: '',
                estado: 1,
                listaDocumentos: [
                    /* {
                                        idIncidenciaSoftwareDoc: '',
                                        idIncidenciaSoftware: '',
                                        urlDocumento: '',
                                        nombreDocumento: '',
                                        documento: null
                                    } */
                ],
            },
            datosSession: {
                idCliente: ''
            },
            disabled: false,
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        abrirModalReabrirIncidencia(param) {
            let me = this;
            me.datosIncidencia.idIncidenciaSoftware = param.item.idIncidenciaSoftware;
            me.datosIncidencia.tipoTarea = param.item.tipoTarea;
            me.datosIncidencia.incidencia = param.item.incidencia;
            me.datosIncidencia.fechaNotificacion = param.item.fechaNotificacion;
            me.datosIncidencia.fechaPrevistaResolucion = param.item.fechaPrevistaResolucion;
            me.datosIncidencia.fechaResolucion = null
            me.datosIncidencia.comentarioProgramador = param.item.comentarioProgramador

            me.listarIncidenciasSoftwareDocumentos(param.item.idIncidenciaSoftware);

            if (param) this.modalReabrirIncidencia = true;
        },
        listarIncidenciasSoftware() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-incidencias-software", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    me.listaIncidenciaFinalizadas = response.data;
                    me.totalRows = me.listaIncidenciaFinalizadas.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarIncidenciasSoftwareDocumentos(idIncidenciaSoftware) {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-incidencias-software-documentos", {
                    params: {
                        idIncidenciaSoftware: idIncidenciaSoftware
                    }
                })
                .then(function (response) {
                    if (response.data.length > 0) {
                        me.datosIncidencia.listaDocumentos.length = 0;
                        for (const i in response.data) {
                            me.datosIncidencia.listaDocumentos.push({
                                idIncidenciaSoftwareDoc: response.data[i].idIncidenciaSoftwareDoc,
                                idIncidenciaSoftware: response.data[i].idIncidenciaSoftware,
                                urlDocumento: response.data[i].urlDocumento,
                                nombreDocumento: response.data[i].nombreDocumento,
                                documento: null
                            })
                        }
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        reabrirIncidencia() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            let idInc = 0;

            for (const i in me.datosIncidencia.listaDocumentos) {
                if (me.datosIncidencia.listaDocumentos[i].idIncidenciaSoftwareDoc == '' && me.datosIncidencia.listaDocumentos[i].documento != null) {
                    idInc = idInc - 1;
                    formData.append("file&&" + idInc, me.datosIncidencia.listaDocumentos[i].documento);
                } else {
                    formData.append("file&&" + me.datosIncidencia.listaDocumentos[i].idIncidenciaSoftwareDoc, me.datosIncidencia.listaDocumentos[i].documento);
                }
            }

            formData.append("datosIncidencia", JSON.stringify(me.datosIncidencia));
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("folder", 'incidentes/incidencias-de-software');

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-incidencia-software",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.modalReabrirIncidencia = false;
                    me.listarIncidenciasSoftware();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetModalReabrirIncidencia() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
        },
        agregarFilaDocumentos() {
            this.datosIncidencia.listaDocumentos.push({
                idIncidenciaSoftwareDoc: '',
                idIncidenciaSoftware: '',
                urlDocumento: '',
                nombreDocumento: '',
                documento: null
            });
        },
        quitarFilaDocumento(param) {
            this.datosIncidencia.listaDocumentos.splice(param.index, 1);
        },
        eliminarDocumento(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el documento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-incidencia-software-documento", {
                            idIncidenciaSoftwareDoc: param.item.idIncidenciaSoftwareDoc,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.datosIncidencia.listaDocumentos.splice(param.index, 1);
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalReabrirIncidencia: function (val) {
            if (val == false) {
                this.resetModalReabrirIncidencia();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;

            this.listarIncidenciasSoftware();
        }
    }

}
</script>
